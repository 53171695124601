import { useRoundware } from '../hooks';
import React, { Fragment, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';

const InfoPopup = () => {
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Button
				onClick={handleClickOpen}
				className={classes.infoButton}
			>
				INFO
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title' style={{background: '#c03816'}}>¿QUÉ ES EL CARNAVAL CHICA ?</DialogTitle>
				<DialogContent dividers style={{background: '#c03816'}}>
					<Typography variant={'h6'} gutterBottom>
						Es un evento performativo de arte público colaborativo creado por un
						colectivo de mujeres artistas y guardianas de oficios que invitan a
						la ciudadanía a sembrar un carnaval para Bogotá.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						El CARNAVAL LA CHICA se alinea con el equinoccio el 23 de Septiembre
						y con la antigua festividad de la Koya Raymi Quilla que en la cordillera
						de los andes, celebra la femineidad y la fecundidad. Celebramos la
						cosecha, los mercados campesinos, las huertas comunitarias, los
						grandes árboles y los antiguos fermentos.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						Activamos prácticas del Arte del Origen y proponemos una manera
						alternativa de uso de la creatividad al servicio de la comunidad.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						Del 20 al 23 de septiembre de 2023, se hará un paso a paso de cómo
						se genera una fiesta sagrada según la tradición andina que va desde
						crear un altar con objetos e indumentaria, que será activado con
						aguas floridas y hasta preparar la ofrenda final el último día.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						Durante cuatro días se vivirá un espectáculo del trabajo colectivo
						con diversos talleres y de la unión de conocimientos se recogerá
						una gran cosecha de arte para todas y todos.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						Será un encuentro de diversas formas de arte privado y público y
						un tejido de tecnologías ancestrales y contemporáneas.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						CARNAVAL LA CHICA para toda la familia!
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						Ofrenda, pagamento, rogativa y romería
					</Typography>
					<Divider />
					<Typography variant={'h6'} gutterBottom>
						<br />
						¿QUÉ ES LA GUACA SONORA?
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						El Carnaval deja una memoria sonora alojada en el app Carnaval La
						Chica con el sistema <Link href='https://roundware.org'>Roundware</Link>&nbsp;
						desarrollada por Halsey Burgund.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						La idea es dejar una memoria sonora geolocalizada en el parque de
						la 60 con 7  y sus alrededores que dé cuenta de lo sucedido en
						esa semana del carnaval.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						Incluirá relatos del barrio, del carnaval, historias sobre la
						guaca y testimonios de los participantes en los talleres.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						Esta posibilidad de memoria invisible pero permanente explora las
						posibilidades del Sonido Aumentado con la herramienta Roundware:
						una plataforma de audio contributiva que permite aumentar el paisaje
						físico con capas de música y voces grabadas que responden
						dinámicamente al movimiento del usuario y su ubicación.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						Usando una aplicación móvil y auriculares, los participantes se
						sumergen en un paisaje sonoro único e irrepetible que se transforma
						en su recorrido por el espacio.  Las capas transparentes de sonido
						se sobreponen al espacio físico y se experimentan en un espacio de
						sonido secreto e íntimo. El visitante puede escuchar y deambular por
						el espacio y  filtrar su flujo de audio de acuerdo a su recorrido y
						al final de la experiencia grabar sus propios comentarios para agregarlos
						al proyecto. Las contribuciones se etiquetan con información de
						ubicación y metadatos específicos del proyecto. Gradualmente, las
						contribuciones de los usuarios se acumulan en todo el panorama,
						documentando una multiplicidad de voces y experiencias subjetivas a
						lo largo del tiempo. Roundware  invita a reflexionar sobre la
						interacción corporal y la interfaz de usuario en la Realidad Aumentada.
					</Typography>

					{/*<a href="./listen">
            <img id="map" src={assetMapGraphic} style={{width: "100%"}} />
          </a>
          <hr />*/}
				</DialogContent>
				<DialogActions style={{background: '#c03816'}}>
					<Button onClick={handleClose} color='secondary' autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default InfoPopup;

export const useStyles = makeStyles((theme: { spacing: (arg0: number) => any; breakpoints: { down: (arg0: string) => any } }) => {
	return {
		infoButton: {
			fontSize: '2em',
			background: '#8E191D',
			margin: '5px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.5em',
				margin: '0px',
			},
		},
	};
});
